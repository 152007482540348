import React from "react"
import Section from "../components/blocks/Section/Section"
import TitreBlock from "../components/blocks/TitreBlock/TitreBlock"
import Layout from "../components/layouts/Layout/layout"
import NextFormations from "../components/sections/NextFormations/NextFormations"
import "./etablissement-template.scss"
import componentFactory from "../utils/componentFactory"
import Seo from "../components/seo"

const EtablissementTemplate = ({ pageContext }) => {
  const page = pageContext.page.attributes
  const item = pageContext.item

  const components = page.contenu.map(i => componentFactory(i, pageContext))
  const title = item.attributes.entiteJuridique

  const contact = item.attributes.contacts.data[0]

  return (
    <>
      <Seo description={`Découvrez les formations RePairs Aidants proposées par ${item.attributes.entiteJuridique} auprès de notre page dédiée.`} title={title} lang="fr"></Seo>
      <Layout chemin={[{titre:"Formations en France", lien:"/formations-en-france/"},{titre: "Les Stuctures"},{titre: title}]}>
        <Section className="titre_page_statique section_titre_template_etablissement">
          <TitreBlock
            title={title}
            baseline={page.sousTitre}
            level="h1"
          ></TitreBlock>
          <p>
            {item.attributes.adresse} <br />
            {item.attributes.codePostal} {item.attributes.ville}
          </p>
          <p>
            {contact?.attributes.nom} {contact?.attributes.prenom}
          </p>
          <p>
            {contact?.attributes.email} {contact?.attributes.fixe}{" "}
            {contact?.attributes.mobile}
          </p>
        </Section>
        <NextFormations
          strapiUrl={pageContext.strapiUrl}
          etablissementId={item.id}
          etablissementName={item.attributes.entiteJuridique}
        />

        {components}
      </Layout>
    </>
  )
}

export default EtablissementTemplate
